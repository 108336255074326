import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VRow, {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "9"
    }
  }, [_c(VTextField, {
    staticClass: "px-8 mt-8",
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Textsuche (mind. 3 Zeichen)",
      "rules": [_vm.twoCharactersValidation],
      "clearable": "",
      "underlined": ""
    },
    on: {
      "input": _vm.searchTrigger
    },
    model: {
      value: _vm.fullTextSearchInput,
      callback: function ($$v) {
        _vm.fullTextSearchInput = $$v;
      },
      expression: "fullTextSearchInput"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "1"
    }
  }, [_c('AddressFilterDialog', {
    on: {
      "toggle": function ($event) {
        _vm.filterSet = !_vm.filterSet;
      },
      "searchTriggered": function ($event) {
        _vm.initiateSearchUI(), _vm.scrollToResults();
      },
      "searchSuccess": _vm.setResults
    }
  })], 1), _c(VCol, {
    attrs: {
      "aria-colspan": "12",
      "sm": "1"
    }
  }, [_c('AddAddressDialog')], 1)], 1), _c(VDivider, {
    staticClass: "mt-6"
  }), _c('div', {
    ref: "resultsTable"
  }, [_vm.results.length > 0 ? _c(VDataTable, {
    staticClass: "mt-6 datatable-row-pointer",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.results,
      "items-per-page": 10,
      "item-key": "lfdnr",
      "loading": _vm.loading,
      "loading-text": "Adressen werden geladen ..."
    },
    on: {
      "click:row": _vm.openAddressDataTable
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function ({
        item
      }) {
        return [_c(VTooltip, {
          attrs: {
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              on
            }) {
              return [_c(VAvatar, _vm._g({
                staticClass: "mr-4 elevation-2",
                attrs: {
                  "size": "34",
                  "color": item.active ? 'white' : 'red'
                }
              }, on), [_c(VIcon, {
                attrs: {
                  "color": item.active ? 'black' : 'white'
                }
              }, [_vm._v(_vm._s(_vm.addressType(item).icon))])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(_vm.addressType(item).name))]), _c('br'), !item.active ? _c('span', [_vm._v("inaktiv")]) : _vm._e()]), _vm._v(" " + _vm._s(item.name) + " ")];
      }
    }, {
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Daten gefunden ")];
      },
      proxy: true
    }, {
      key: "footer.prepend",
      fn: function () {
        return [_c(VBtn, {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "depressed": "",
            "color": "white"
          },
          on: {
            "click": function ($event) {
              return _vm.scrollToTop();
            }
          }
        }, [_vm._v(" Nach oben "), _c(VIcon, [_vm._v("mdi-arrow-up-thin")])], 1)];
      },
      proxy: true
    }], null, false, 272687926)
  }) : _vm._e()], 1), _c(VLayout, {
    attrs: {
      "justify-center": "",
      "align-center": ""
    }
  }, [_c(VFlex, {
    attrs: {
      "shrink": ""
    }
  }, [_vm.results.length == 0 && _vm.loading == false ? _c(VImg, {
    staticClass: "mt-16",
    attrs: {
      "src": require("@/assets/svg/undraw_void.svg"),
      "max-width": "350px"
    }
  }) : _vm._e()], 1)], 1), _c('br'), _vm.results.length == 0 && _vm.fullTextSearchInput.length <= 2 && _vm.loading == false ? _c('h2', {
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" Es konnten keine Adressen gefunden werden. ")]) : _vm._e(), _vm.results.length == 0 && _vm.fullTextSearchInput.length > 2 && _vm.loading == false ? _c('h2', {
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" Zu dieser Suche konnten keine Adressen gefunden werden. ")]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };